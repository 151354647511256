@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* //////////////////////////////////////////////////Start NavBar/////////////////////////////////////// */

.smallDevice svg {
  color: black !important;
  width: 40px !important;
  height: 40px !important;
}

.headerCarouselSection nav {
  background-color: none !important;
  box-shadow: none !important;
  background: url('/public/icons/firstbg.png') !important;

}

img.logoImg {
  margin-top: 15px !important;
  margin-left: 30px;
}

.headerMenuButton button.active {
  color: #07638F !important;
}

.headerMenuButton button {
  color: #4A5C65 !important;
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins';
  margin-left: 25px;
  font-weight: 900;
}

.headerMenuButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mainMenuDropdown {
  display: flex;
  align-items: center;
  color: #07638f;
  cursor: pointer;
  margin-right: 30px;
  font-family: 'Poppins';
}

.mainMenuDropdown .gloveImg {
  margin-right: 5px !important;
}

.mainMenuDropdown .dropIcon {
  margin-left: 5px !important;
  width: 20px;
}

/* //////////////////////////////////////////////////End NavBar/////////////////////////////////////// */

/* //////////////////////////////////////////////////Start Carousel Slider/////////////////////////////////////// */
.slick-next {
  right: 10px !important;
}

.slick-prev {
  left: 35px !important;
  z-index: 1;
  background: url(/public//icons/leftarrow.svg);
  height: 56px;
  width: 56px;
  background-position: center;
  border-radius: 50%;
}

.slick-prev:hover {
  left: 35px !important;
  z-index: 1;
  background: url(/public//icons/leftarrow.svg) !important;
  height: 56px !important;
  width: 56px !important;
  background-position: center !important;
  border-radius: 50% !important;
}

.slick-next {
  right: 35px !important;
  z-index: 1;
  background: url(/public//icons/rightarrow.svg);
  height: 56px !important;
  width: 56px !important;
  background-position: center !important;
  border-radius: 50% !important;
}

.slick-next:hover {
  right: 35px !important;
  z-index: 1;
  background: url(/public//icons/rightarrow.svg) !important;
  height: 56px !important;
  width: 56px !important;
  background-position: center !important;
  border-radius: 50% !important;
}

.slick-prev:before,
.slick-next:before {
  display: none !important;
}

.slick-dots {
  bottom: 30px !important;
}

.slick-slide img {
  width: 100% !important;
}

.slick-dots li button:before {
  color: white !important;
  font-size: 50px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '-';
}

.sliderContainer h2 {
  color: white !important;
  font-weight: 1000;
  font-family: 'Poppins';
  text-align: center;
  position: absolute;
  bottom: 40%;
  width: 33%;
  font-size: 80px;
}

.headerCarouselSection .simpleSliderComp {
  max-width: 100%;
  padding: 0px 40px;
}

.headerCarouselSection {
  padding-bottom: 50px;
  /* background-color: #E5F5FD; */
  background: url('/public/icons/firstbg.png') !important;
}

.simpleSliderComp {
  margin-top: 80px;
  max-width: 100%;
}


/* ///////////////////////////////////////End Carousel Slider/////////////////////////////////////////////////////// */

/* //////////////////////////////////////Start E-Parking Section//////////////////////////////////////////////////// */
.eParkingSection {
  background-color: #f6f8fa;
  padding:50px 50px 80px 50px !important;
  max-width: 100% !important;
}

.eParkingSection .imgSec {
  width: auto;
  height: 600px;
  margin-top: 50px;
}

.eParkingSection .imgSec img {
  width: 100%;
  height: 100%;
}

.eParkingSection .EParkingContent {
  padding: 0px 140px;
}

.eParkingSection h3 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 60px;
  color: #0F171B;
  margin-top: 20px;
}

.eParkingSection h6 {
  font-size: 18px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
  margin-top: 30px;
}

.eParkingSection .eParkingList .listContainer {
  display: flex;
  align-items: center;
}

/* .eParkingSection .eParkingList .listContainer .ListIcon{
  width: 40px;
  height: 40px;
  border: 1px solid #07638f;
  border-radius: 50%;
  margin-right: 10px;
  background-color: white;
} */
.eParkingSection .eParkingList .listContainer h6 {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
  margin-top: 20px;
  display: flex;
}

.eParkingSection .eParkingList .listContainer .one::before {
  content: "1";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eParkingSection .eParkingList .listContainer .two::before {
  content: "2";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eParkingSection .eParkingList .listContainer .three::before {
  content: "3";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eParkingSection .eParkingList .listContainer .four::before {
  content: "4";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eParkingSection .eParkingList .listContainer .five::before {
  content: "5";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eParkingSection .eParkingList .listContainer .six::before {
  content: "6";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eParkingSection .eParkingList .listContainer .seven::before {
  content: "7";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eParkingSection .eParkingList .listContainer .eight::before {
  content: "8";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eParkingSection .eParkingList .listContainer .nine::before {
  content: "9";
  width: 28px;
  height: 28px;
  padding: 7px 15px;
  /* border: 1px solid #07638f;
  border-radius: 50%; */
  margin-right: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* //////////////////////////////////////End E-Parking Section//////////////////////////////////////////////////// */

/* //////////////////////////////////////Start Parking Section//////////////////////////////////////////////////// */
.parkingSection {
  margin-top: 80px;
}

.parkingSection h3 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 60px;
  color: #0F171B;
}

.parkingSection h6 {
  font-size: 18px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
}

.parkingStepperSection {
  margin-top: 10px;
  padding: 0px 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 90px;
}

.parkingStepperSection h3 {
  font-weight: 1000;
  font-size: 25px;
  font-family: 'Poppins';
  color: black;

}

.parkingStepperSection p {
  font-size: 14px;
  line-height: 25px;
  color: #4A5C65;
  font-family: 'Poppins';
  margin-top: 15px;
}

.parkingStepperSection svg {
  display: none !important;
}

.parkingStepperSection .leftStripper .stepperIcon {
  background-image: url(/public/icons/location.svg) !important;
  background-size: 70% 70% !important;
  background-position: center !important;
  width: 250px !important;
  height: 250px !important;
  background-repeat: no-repeat !important;
  border-radius: 50% !important;
  margin: auto !important;
}

.parkingStepperSection .middleStripper .stepperIcon {
  background-image: url(/public/icons/quick.svg);
  background-size: 70% 70% !important;
  background-position: center !important;
  width: 250px !important;
  height: 250px !important;
  background-repeat: no-repeat !important;
  border-radius: 50% !important;
  margin: auto !important;
}

.parkingStepperSection .rightStripper .stepperIcon {
  background-image: url(/public/icons/safe.svg);
  background-size: 70% 70% !important;
  background-position: center !important;
  width: 250px !important;
  height: 250px !important;
  background-repeat: no-repeat !important;
  border-radius: 50% !important;
  margin: auto !important;
}



/* //////////////////////////////////////End Parking Section//////////////////////////////////////////////////// */


/* /////////////////////////////////////////Start About Section//////////////////////////////////////// */
/* .aboutUsSection {
  margin-top: 80px;
  padding: 0px 50px;
}

.aboutUsSection .imgContainer {
  height: 500px;
}

.aboutUsSection .aboutLeftSection .imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutUsSection .aboutRightSection .aboutHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 60px;
  color: #0F171B;
} */


/* ////////////////////// */
.aboutSection {
  position: relative;
  width: 100%;
  max-width: 100% !important;
  padding: 0px !important;
  margin-top: 100px;
}

.aboutSection .aboutImg {
  width: 100%;
  height: 700px;
}

.aboutSection .aboutImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutSection .aboutContent {
  background-color: #07638f;
  width: 900px;
  height: 480px;
  position: absolute;
  bottom: -130px;
  right: 0px;
  padding: 50px 50px 50px 66px;
  overflow: auto;
}

.aboutSection .aboutContent .aboutHeading {
  color: white;
  font-size: 60px;
  font-family: 'Poppins';
}

.contentParagraph {
  color: white;
  font-size: 18px !important;
  font-family: 'Poppins' !important;
  line-height: 30px !important;
  font-weight: 300 !important;
  margin-top: 20px !important;
  text-align: justify;
}

.contentParagraph span {
  color: white;
  font-size: 15px !important;
  font-family: 'Poppins' !important;
  line-height: 30px !important;
  font-weight: 800 !important;
  margin-left: 10px !important;
  cursor: pointer;

}

/* .readMore {
  color: white !important;
  margin-top: 30px !important;
  padding: 0px !important;
} */

/* .listItem {
  color: white;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500 !important;
  margin-top: 13px;
  padding-left: 0px !important;
} */

/* .listItem img {
  margin-right: 15px;
} */



/* /////////////////////////////////////////End About Section//////////////////////////////////////// */




/* //////////////////////////////////////Start Our Location Section//////////////////////////////////////////////////// */
.locationSection {
  margin-top: 60px;
}
.privacypolicySection {
  margin-top: 220px;
}
.orientationTabPills {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.locationSection h3 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 60px;
  color: #0F171B;
}

.locationSection h6 {
  font-size: 18px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
}

.locationTabPills button.tabBtn {
  color: #000000;
  padding: 25px !important;
  text-align: left !important;
  text-transform: capitalize;
  font-size: 19px;
  font-family: 'Poppins';
  max-width: 900px !important;
  padding-left: 70px !important;
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}

.locationTabPills button.tabBtn.Mui-selected {
  color: #000000;
  background: #E5F5FD;
  padding: 25px !important;
  padding-left: 70px !important;
}

.locationTabPills {
  margin-top: 60px;
}

.locationTabPills .rightSideData p {
  width: 100%;
  height: 670px;
}

.locationTabPills .rightSideData img {
  width: 100% !important;
  height: 100%;
  object-fit: cover !important;
}

.locationTabPills .rightSideData>div {
  padding: 0px 60px 0px 50px !important;
}

/* //////////////////////////////////////End Our Location Section//////////////////////////////////////////////////// */

/* //////////////////////////////////////Start Customer Section//////////////////////////////////////////////////// */
.customerSection {
  margin-top: 80px;
}

.customerSection h3 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 60px;
  color: #0F171B;
}

.customerSection h6 {
  font-size: 18px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
}

.customerCardsBox {
  border: 1px solid #e8e8e8;
  border-radius: 15px !important;
  box-shadow: none !important;
}

.customerCards .cardsImg {
  height: 110px;
}

.customerCards p {
  font-size: 18px;
  line-height: 25px;
  color: #4A5C65;
  font-family: 'Poppins';
  margin-top: 20px;
}

.customerCards p.underLine {
  margin-top: 60px;
}

.customerCards h5 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 22px;
  color: #0F171B;
}


/* //////////////////////////////////////End Customer Section//////////////////////////////////////////////////// */

/* //////////////////////////////////////Start Footer Section//////////////////////////////////////////////////// */
.footerSection {
  background: #E5F5FD;
  /* padding: 0px 275px 25px 275px; */
  width: 100%;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerSection>div {
  width: 1280px;
}

.footerSection .leftSectionFooter p {
  font-size: 16px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
}

.footerSection .middleSectionFooter {
  margin-top: 25px;
  padding-left: 0px !important;
}

.footerSection .middleSectionFooter .footerNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
}

.footerSection .rightSectionFooter {
  margin-top: 20px;
}

.footerSection .middleSectionFooter .footerNav p {
  margin-right: 30px;
  cursor: pointer;
}

.footerSection .middleSectionFooter p {
  color: #4A5C65 !important;
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins';
  margin-left: 25px;
  font-weight: 900 !important;
}


.footerSection .allRightReserve {
  text-align: center;
  width: 100%;
  color: #4A5C65;
  font-weight: 900;
  font-size: 16px;
  border-top: 2px solid #bdd6e5;
  padding-top: 25px;
  margin-top: 40px;
  padding-bottom: 20px;
}

.rightInnerSection {
  justify-content: flex-start !important;
}

.socialSection .rightInnerImg {
  margin: auto !important;
}

.footerSection .rightSectionFooter {
  /* padding-left:100px !important; */
}

.footerSection .rightSectionFooter h3 {
  text-align: left;
  color: #4A5C65 !important;
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Poppins';
  /* margin-left: 25px; */
  /* padding-right: 30px; */
  margin-top: 30px;
  font-weight: 900;
}

.footerSection .rightSectionFooter .address {
  font-size: 16px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
  text-align: left;
  margin-top: 20px;
}

.footerSection .rightSectionFooter .telEmail {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  color: #4A5C65;
  font-family: 'Poppins';
  text-align: left;
  margin-top: 10px;
}

.footerSection .rightSectionFooter .telEmail img {
  margin-right: 10px;
}

/* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */




/* ////////////////////////////////////////Start Responsive Code///////////////////////////////////////////////// */
@media screen and (max-width:1440px) {

  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */
  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */
  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */
  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */
  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */
  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */
  /* //////////////////////////////////////Start Footer Section//////////////////////////////////////////////////// */
  .footerSection .middleSectionFooter p {
    font-size: 17px;
    margin-left: 10px;
  }

  /* //////////////////////////////////////End Footer Section//////////////////////////////////////////////////// */

  /* ////////////////////////////Carousel//////////////////////// */
  .sliderContainer h2 {
    color: white !important;
    font-weight: 1000;
    font-family: 'Poppins';
    text-align: center;
    position: absolute;
    bottom: 40%;
    width: 33%;
    font-size: 60px;
  }

  .eParkingSection h3 {
    font-size: 50px;
  }

  .parkingSection h3 {
    font-size: 50px;
  }

  .aboutSection .aboutContent .aboutHeading {
    font-size: 50px;
  }

  .locationSection h3 {
    font-size: 50px;
  }

  .customerSection h3 {
    font-size: 50px;
  }

  .leftStripper,
  .middleStripper,
  .rightStripper {
    margin-right: 15px;
  }

  .parkingStepperSection {
    padding: 0px 100px !important;
  }
}

@media screen and (max-width:1360px) {
  .footerSection>div {
    width: 100%;
    padding: 0px 40px;
  }
}

@media screen and (max-width:1250px) {
  .sliderContainer h2 {
    font-size: 50px !important;
  }

  .eParkingSection .EParkingContent {
    padding: 0px 70px;
  }
}

@media screen and (max-width:1075px) {
  img.logoImg {
    width: 90px;
  }

  .headerMenuButton button {
    font-size: 16px;
  }
}
.locationTabPills .rightSideData {
  /* width: calc(100% - 360px); */
  width: 100% !important;
  /* height: 500px; */
}
@media screen and (max-width:1024px) {
  .sliderContainer h2 {
    font-size: 50px;
    bottom: 40%;
  }

  .eParkingSection {
    padding: 50px 40px !important;
  }

  .eParkingSection .imgSec {
    width: auto;
    height: 500px;
  }

  .eParkingSection .eParkingList .listContainer h6 {
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }

  .aboutSection {
    margin-top: 60px;
  }

  .contentParagraph {
    color: white;
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .aboutSection .aboutContent .aboutHeading {
    font-size: 45px;
  }

  /* .listItem {
    font-size: 17px;
    margin-top: 13px;
    padding-left: 0px !important;
  } */

  .aboutSection .aboutContent {
    width: 650px;
    height: 453px;
    position: absolute;
    bottom: -130px;
    right: 0px;
    padding: 35px 41px 27px 49px;
  }

  .customerSection {
    margin-top: 60px;
  }

  .customerSection h3 {
    font-size: 45px;
  }

  .customerSection h6 {
    font-size: 16px;
    line-height: 25px;
  }

  .customerCards p {
    font-size: 16px;
    line-height: 23px;
    margin-top: 20px;
  }

  .customerCards h5 {
    font-weight: 600;
    font-size: 20px;
  }

  .locationTabPills .rightSideData {
    width: calc(100% - 360px);
    /* width: 100% !important; */
    /* height: 500px; */
  }

  .locationSection {
    margin-top: 180px;
  }

  .locationSection h3 {
    font-size: 45px;
  }

  .locationSection h6 {
    font-size: 16px;
    line-height: 25px;
  }

  .locationTabPills button.tabBtn {
    padding: 25px !important;
    font-size: 17px;
    max-width: 900px !important;
    padding-left: 70px !important;
  }

  .locationTabPills .rightSideData p {
    width: 100%;
    height: 500px;
  }

  .parkingSection {
    margin-top: 60px;
  }

  .parkingStepperSection {
    margin-top: 40px;
  }

  .eParkingSection h3 {
    font-size: 45px;
    margin-top: 20px;
  }

  .eParkingSection h6 {
    font-size: 16px;
    line-height: 25px;
  }

  .parkingSection h3 {
    font-size: 45px;
  }

  .parkingSection h6 {
    font-size: 16px;
    line-height: 25px;
  }

  .parkingStepperSection h3 {
    font-size: 22px;
  }

  .parkingStepperSection .leftStripper .stepperIcon {
    width: 200px !important;
    height: 200px !important;
    margin: auto !important;
  }

  .parkingStepperSection .middleStripper .stepperIcon {
    width: 200px !important;
    height: 200px !important;
    margin: auto !important;
  }

  .parkingStepperSection .rightStripper .stepperIcon {
    width: 200px !important;
    height: 200px !important;
    margin: auto !important;
  }

  .parkingStepperSection {
    padding: 0px 60px;
  }

  .footerSection .middleSectionFooter p {
    font-size: 18px;
  }

  .footerSection .allRightReserve {
    font-size: 16px;
    padding-top: 25px;
  }

  .footerSection .leftSectionFooter p {
    font-size: 15px;
    line-height: 25px;
  }

  /* ////////////////////////////Carousel//////////////////////// */
  .sliderContainer h2 {
    color: white !important;
    font-weight: 1000;
    font-family: 'Poppins';
    text-align: center;
    position: absolute;
    bottom: 40%;
    width: 33%;
    font-size: 50px;
  }
}

@media screen and (max-width:991px) {
  .footerSection>div {
    width: 100%;
    padding: 0px 0px;
  }

  .eParkingSection .imgSec {
    width: auto;
    height: 400px;
  }

  .eParkingSection .eParkingList .listContainer h6 {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }

  .rightInnerSection {
    justify-content: flex-start !important;
  }

  .headerCarouselSection .simpleSliderComp {
    max-width: 100%;
    padding: 0px 40px;
  }

  .sliderContainer h2 {
    font-size: 35px;
  }

  img.logoImg {
    margin-top: 15px !important;
    margin-left: 15px;
  }

  .mainMenuDropdown {
    margin-right: 15px !important;
  }

  .headerMenuButton button {
    font-size: 15px !important;
    margin-left: 18px;
  }

  .headerCarouselSection {
    padding-bottom: 25px;
  }

  .simpleSliderComp {
    margin-top: 80px;
  }

  .aboutSection .aboutImg {
    width: 100%;
    height: 530px;
  }

  .aboutSection .aboutContent {
    width: 600px;
    height: 420px;
    position: absolute;
    bottom: -130px;
    right: 0px;
    padding: 16px 27px 27px 27px;
  }

  .aboutSection .aboutContent .aboutHeading {
    font-size: 40px;
  }

  .contentParagraph {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  /* .listItem {
    font-size: 15px;
    margin-top: 5px;
    padding-left: 0px !important;
  } */

  .customerSection h3 {
    font-size: 35px;
  }

  .customerSection h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .customerCards .cardsImg {
    height: 90px;
    margin: 20px auto auto !important;
  }

  .customerCards p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  .customerCards h5 {
    font-size: 18px;
    font-size: 18px;
    margin-top: 30px;
  }

  .customerCards p.underLine {
    margin-top: 50px;
  }

  .customerCardsBox {
    max-width: 100% !important;
  }

  .locationTabPills button.tabBtn {
    padding: 16px !important;
    font-size: 15px;
  }

  .locationTabPills button.tabBtn.Mui-selected {
    padding: 16px !important;
    font-size: 15px;
  }

  .locationSection h3 {
    font-size: 35px;
  }

  .locationSection h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .locationTabPills .rightSideData p {
    width: 100%;
    height: 370px;
  }

  .locationTabPills .rightSideData>div {
    padding: 0px 35px 0px 30px !important;

  }

  .eParkingSection h3 {
    font-size: 35px;
  }

  .eParkingSection h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .parkingSection h3 {
    font-size: 35px;
  }

  .parkingSection h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .parkingStepperSection .leftStripper .stepperIcon {
    width: 150px !important;
    height: 150px !important;
    margin: auto !important;
  }

  .parkingStepperSection .middleStripper .stepperIcon {
    width: 150px !important;
    height: 150px !important;
    margin: auto !important;
  }

  .parkingStepperSection .rightStripper .stepperIcon {
    width: 150px !important;
    height: 150px !important;
    margin: auto !important;
  }

  .parkingStepperSection h3 {
    font-size: 18px;
  }

  .parkingStepperSection p {
    font-size: 13px;
    line-height: 20px;
    margin-top: 15px;
  }

  .parkingStepperSection {
    padding: 0px 35px;
  }

  .parkingStepperSection {
    margin-top: 10px;
  }

  /* ////////////////////////////Carousel//////////////////////// */
  .sliderContainer h2 {
    font-size: 40px !important;
  }

  .footerSection .middleSectionFooter p {
    font-size: 16px;
  }

  .footerSection {
    padding: 0px 35px 25px 35px;
    margin-top: 60px;
  }

  .footerSection .allRightReserve {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .headerCarouselSection .simpleSliderComp {
    max-width: 100%;
    padding: 0px 20px;
  }

  .eParkingSection {
    padding: 25px 25px !important;
  }

  .eParkingSection .imgSec {
    height: 325px;
  }

  .eParkingSection .EParkingContent {
    padding: 0px 20px;
  }

  .slick-prev {
    left: 15px !important;
    height: 41px;
    width: 40px;
  }

  .slick-next {
    right: 15px !important;
    height: 41px;
    width: 40px;
  }

  img.logoImg {
    margin-top: 15px !important;
    margin-left: 0px;
  }

  .sliderContainer h2 {
    font-size: 33px !important;
  }

  .headerMenuButton button {
    font-size: 14px !important;
  }

  .headerMenuButton button {
    margin-left: 0px;
  }

  .mainMenuDropdown {
    margin-right: 0px !important;
  }

  .aboutSection .aboutImg {
    height: 450px;
  }

  .aboutSection .aboutContent {
    width: 540px;
    height: 390px;
    position: absolute;
    bottom: -100px;
    right: 0px;
    padding: 14px 20px 20px 24px;
  }

  .aboutSection .aboutContent .aboutHeading {
    font-size: 35px;
  }

  .contentParagraph {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  /* .listItem {
    font-size: 14px;
    margin-top: 0px;
    padding-left: 0px !important;
  } */

  .locationSection {
    margin-top: 140px;
  }

  .parkingSection {
    margin-top: 35px;
  }

  .parkingStepperSection {
    padding: 0px 20px !important;
  }

  .parkingStepperSection .leftStripper .stepperIcon {
    width: 120px !important;
    height: 120px !important;
  }

  .parkingStepperSection .middleStripper .stepperIcon {
    width: 120px !important;
    height: 120px !important;
  }

  .parkingStepperSection .rightStripper .stepperIcon {
    width: 120px !important;
    height: 120px !important;
  }

  .parkingStepperSection h3 {
    font-size: 16px;
  }

  .parkingStepperSection p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;
  }

  .footerleftIcon {
    height: 40px;
    margin-top: 50px !important;
  }

  .footerSection .leftSectionFooter p {
    font-size: 14px;
    line-height: 20px;
  }

  .middleSectionFooter {
    padding: 0px !important;
    margin-top: 60px !important;
  }

  .middleSectionFooter>div {
    margin-top: 30px;
  }

  .footerSection .middleSectionFooter p {
    font-size: 15px !important;
    margin-left: 10px !important;
  }

  .footerSection .allRightReserve {
    font-size: 12px !important;
    padding-top: 14px !important;
  }
}

@media screen and (min-width:700px) {
  /* .headerMenuButton>div{
  display:none !important;
  } */
}

@media screen and (max-width:700px) {
  .logoImg {
    width: 70px;
  }
}

@media screen and (max-width:640px) {
  .sliderContainer h2 {
    font-size: 30px;
  }
}

@media screen and (max-width:600px) {
  .headerCarouselSection .simpleSliderComp {
    padding: 0px 15px;
  }

  .footerSection .middleSectionFooter .footerNav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    flex-wrap: wrap;
  }

  .css-130f8nx {
    display: block;
    width: 100%;
  }

  .headerMenuButton {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }

  .footerSection .middleSectionFooter {
    margin-top: 10px !important;
    padding-left: 0px !important;
  }

  .footerSection .rightSectionFooter {
    padding-top: 0px !important
  }


  .footerSection .rightSectionFooter {
    margin-top: 0px;
  }

  .mainMenuDropdown .dropIcon {
    margin-left: 5px !important;
    width: 16px;
  }

  .mainMenuDropdown .gloveImg {
    margin-right: 5px !important;
    width: 16px !important;

  }

  .slick-prev {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }

  .sliderContainer h2 {
    font-size: 25px !important;
    bottom: 40%;
  }

  .aboutSection .aboutContent {
    background-color: #07638f;
    width: 100%;
    height: 375px;
    position: absolute;
    bottom: -235px;
    right: 0px;
    padding: 10px 10px 10px 16px;
  }

  /* 
  .listItem {
    font-size: 14px;
    margin-top: 0px;
    padding-left: 0px !important;
  }

  .listItem img {
    margin-right: 9px;
    width: 10px;
  } */

  .locationSection {
    margin-top: 160px;
  }

  .locationTabPills button.tabBtn {
    padding: 10px !important;
    font-size: 16px !important;
    width: 100% !important;
  }

  .locationTabPills .rightSideData>div {
    padding: 0px 15px 0px 15px !important;
  }

  .leftSectionFooter,
  .socialSection {
    padding-top: 0px !important;
  }

  .footerSection {
    padding: 0px 20px 10px 20px;
    margin-top: 60px;
  }
}

@media screen and (max-width:575px) {
  .aboutSection {
    margin-top: 30px;
  }

  .aboutSection .aboutImg {
    width: 100%;
    height: 400px;
  }

  .aboutSection .aboutContent .aboutHeading {
    font-size: 25px;
  }

  .contentParagraph {
    font-size: 13px !important;
  }


  /* .listItem {
    line-height: 11px;
  } */

  .customerSection {
    margin-top: 30px;
  }

  .customerSection h3 {
    font-size: 25px;
  }

  .customerSection h6 {
    font-size: 12px;
  }

  .headerCarouselSection {
    padding-bottom: 10px;
  }

  .simpleSliderComp {
    margin-top: 60px;
  }

  .locationSection {
    margin-top: 270px;
  }

  .locationTabPills {
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .locationTabPills .orientationTabPills {
    width: 100%;
  }


  .locationTabPills button.tabBtn {
    font-size: 15px;
  }

  .locationTabPills button.tabBtn.Mui-selected {
    padding: 10px !important;
  }

  .locationTabPills .rightSideData {
    width: 100%;
  }

  .locationTabPills .rightSideData p {
    width: 100%;
    height: 200px;
  }

  .locationSection h3 {
    font-size: 25px;
  }

  .locationSection h6 {
    font-size: 12px;
  }

  .parkingStepperSection {
    margin-top: 30px;
  }

  .parkingStepperSection h3 {
    font-size: 12px;
  }

  .parkingStepperSection p {
    font-size: 11px;
    line-height: 12px;
    margin-top: 10px;
  }

  /* ////////////////////////////////////////////Start E-Parking Solution///////////////////////////// */
  .eParkingSection {
    padding: 20px 18px !important;
  }

  .eParkingSection .imgSec {
    width: auto;
    height: 220px;
  }

  .eParkingSection h3 {
    font-size: 25px;
    margin-top: 30px;
  }

  .eParkingSection h6 {
    font-size: 12px;
  }

  .eParkingSection .EParkingContent {
    padding: 0px 0px;
  }

  .eParkingSection .eParkingList .listContainer h6 {
    font-size: 12px;
  }

  .eParkingSection .eParkingList .listContainer .one::before {
    content: "1";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }

  .eParkingSection .eParkingList .listContainer .two::before {
    content: "2";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }

  .eParkingSection .eParkingList .listContainer .three::before {
    content: "3";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }

  .eParkingSection .eParkingList .listContainer .four::before {
    content: "4";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }
  .eParkingSection .eParkingList .listContainer .five::before {
    content: "5";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }
  .eParkingSection .eParkingList .listContainer .six::before {
    content: "6";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }
  .eParkingSection .eParkingList .listContainer .seven::before {
    content: "7";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }
  .eParkingSection .eParkingList .listContainer .eight::before {
    content: "8";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }
  .eParkingSection .eParkingList .listContainer .nine::before {
    content: "9";
    width: 15px;
    height: 20px;
    padding: 5px 15px;
    margin-right: 20px;
  }



  /* ////////////////////////////////////////////End E-Parking Solution///////////////////////////// */

  .parkingSection {
    margin-top: 30px;
  }

  .parkingSection h3 {
    font-size: 25px;
  }

  .parkingSection h6 {
    font-size: 12px;
  }

  .footerSection .allRightReserve {
    font-size: 11px;
  }

  .socialSection .rightInnerImg {
    margin: 0 !important;
  }

  .rightInnerSection {
    justify-content: start !important;
  }

  .rightInnerSection img {
    width: 35px !important;
    height: 35px !important;
  }

  /* .readMore {
    margin-top: 15px !important;
  } */
}

@media screen and (max-width:425px) {
  .eParkingSection .imgSec {
    width: auto;
    height: 150px;
  }

  .parkingStepperSection {
    min-width: 100%;
    overflow: auto;
    margin-top: 10px;
  }

  .aboutSection .aboutContent {
    background-color: #07638f;
    width: 100%;
    height: 465px;
    position: absolute;
    bottom: -335px;
    right: 0px;
    padding: 10px 10px 10px 16px;
  }

  .locationSection {
    margin-top: 360px;
  }
}

@media screen and (max-width:375px) {
  .sliderContainer h2 {
    font-size: 19px !important;
    bottom: 40%;
  }
}

/* /////////////////////////////////////End Responsive Code//////////////////////////////////// */